export const getCompanyData = async companyID => {
  try {
    const response = await fetch(
      "https://ahmedaglan.github.io/ClientsJSON/Clients.json"
    )
      .then(data => data.json())
      .then(data => {
        const founded = data.ClientsList.find(
          company => company.ClientID === companyID
        );
        if (!founded) throw Error("cannot find companyID");
        return founded;
      });
    //   https://vsoft.com-eg.net/VSoftDemoAPI/api/RunnerUsers/V6/Login
    return {
      ...response,
      baseURL: `${response.APIHost}${response.APIPath}api/RunnerUsers/V6`,
    };
  } catch (err) {
    throw err;
  }
};
export const handleLogin = async (baseURL, loginData, cb) => {
  const { companyID, username, password } = loginData;
  let response = "should change";
  try {
    console.log(baseURL);

    response = await fetch(`${baseURL}/Login`, {
      method: "post",
      body: JSON.stringify({ userName: username, password }),
      headers: {
        CompanyID: companyID,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.Message) {
          throw new Error(data.Message);
        } else {
          cb({ ...data, baseURL, companyID });
          localStorage.setItem(
            "runnerData",
            JSON.stringify({ ...data, baseURL, companyID })
          );
          return { data, baseURL, companyID };
        }
        return response;
      });
  } catch (err) {
    throw err;
  }
};
export const handleConfirm = async (runnerData, setRunnerData, shipments) => {
  const { baseURL, RunnerID, AccessToken, companyID } = runnerData;
  try {
    const response = await fetch(`${baseURL}/ConfirmAWBs/${RunnerID}`, {
      method: "post",
      body: JSON.stringify({ awBs: shipments }),
      headers: {
        CompanyID: companyID,
        AccessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    // handleLogout(baseURL, setRunnerData, AccessToken, RunnerID);
    console.log(shipments);
    return response;
  } catch (err) {
    console.error(err);
    handleLogout(baseURL, setRunnerData, AccessToken, RunnerID);
  }
};
export const handleCheck = async (runnerData, setRunnerData) => {
  console.log(runnerData);
  const { baseURL, RunnerID, AccessToken, companyID } = runnerData;
  try {
    const response = await fetch(`${baseURL}/ConfirmAWBs/${RunnerID}`, {
      method: "post",
      body: JSON.stringify({ awBs: [] }),
      headers: {
        CompanyID: companyID,
        AccessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      setRunnerData(null);
      localStorage.removeItem("runnerData");
    }

    return response;
  } catch (err) {
    console.error(err);
  }
};
export const handleLogout = async (
  baseURL,
  cb,
  accessToken,
  RunnerID,
  companyID
) => {
  try {
    const response = await fetch(`${baseURL}/Logout/${RunnerID}`, {
      method: "post",
      body: {},
      headers: {
        CompanyID: companyID,
        Accept: "application/json",
        "Content-Type": "application/json",
        AccessToken: accessToken,
      },
    })
      .then(data => data.json())
      .then(data => {
        cb(null);
        localStorage.removeItem("runnerData");
      });
  } catch (err) {
    throw err;
  }
};
export const handleRunnerData = () => {
  return {
    getData: async function () {},
    setData: async function (data) {},
    login: async function (loginData, cb) {
      const { companyID, username, password } = loginData;
      try {
        const companyData = await getCompanyData(parseInt(companyID));
        const rData = await handleLogin(
          companyData.baseURL,
          { username, password, companyID },
          cb
        );
        console.log("loginData", rData);
      } catch (err) {
        console.log(err.message);
      }
    },
    logout: async function (cb) {
      cb(null);
    },
  };
};
