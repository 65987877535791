import { useContext } from "react";
import { handleConfirm, handleLogout } from "../helpers";
import { RunnerContext } from "../Main";
import { useNavigate } from "react-router-dom";
export default function ConfirmationDialog({ setOpen, shipments , setShipments }) {
  const navigate = useNavigate()
  const { runnerData, setRunnerData } = useContext(RunnerContext);
  const submitConfirm = async () => {
    await handleConfirm(runnerData, setRunnerData, shipments).then(()=> {
      setOpen(false)
      setShipments([])
    });
  };
  return (
    <div className="confirmation-container">
      <div className="confirmation-dialog">
        <div className="confirmation-body">
          you are about to confirm that you are recieving {shipments?.length}{" "}
          {shipments?.length === 1 ? "shipment" : "shipments"}
        </div>
        <div className="confirmation-actions">
          <div className="confirmation-cancel" onClick={() => setOpen(false)}>
            cancel
          </div>
          <div className="confirmation-ok" onClick={submitConfirm}>
            confirm
          </div>
        </div>
      </div>
    </div>
  );
}
